<template>
  <div class="project">
    <ProjectIndex @projectTabNum="getProjectTabNum" />

    <div class="relative flex flex-col min-w-0 break-words gap-6 project">
      <div class="container my-16 grid flex-auto gap-y-16" data-aos="fade-left">
        <div :class="`block-${item.engType}`" v-for="(item, projectIndex) in project[projectTabNum].content"
          :key="projectIndex" data-aos="fade-left">
          <p class="text-4xl mb-16 border-l-4 border-Primary pl-4 text-white">{{ item.type }}</p>
          <div v-show="projectIndex === 0" class="mb-8" v-for="(area, contentIndex) in item.typeContent"
            :key="contentIndex">
            <p class="text-2xl mb-5 text-white">{{ area.area }}</p>
            <div class="flex">
              <!-- Modal toggle -->
              <button class="fill-btn px-5 py-2.5 text-sm" @click="openModal(item.regionEng)"
                v-for="(item, areaContentIndex) in area.areaContent" :key="areaContentIndex">
                {{ item.region }}
              </button>
              <p class="text-white" v-show="area.areaContent === undefined">敬请期待</p>
            </div>
            <!-- Main modal -->
            <div v-for="(item, areaContentIndex) in area.areaContent" :key="areaContentIndex"
              :id="`${item.regionEng}-modal`" tabindex="-1" style="z-index: -1;"
              class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center"
              :class="{
                hidden: projectModal !== item.regionEng,
                flex: projectModal === item.regionEng,
              }" aria-modal="true" role="dialog">
              <div class="relative p-4 w-full max-w-2xl h-auto">
                <!-- Modal content -->
                <div class="relative bg-white rounded-lg shadow-2xl">
                  <!-- Modal header -->
                  <div class="flex justify-between items-start p-4 rounded-t border-b">
                    <h3 class="text-xl font-semibold text-Primary">
                      {{ item.region }}
                    </h3>
                    <button type="button" @click="closeModal(item.regionEng)"
                      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                  </div>
                  <!-- Modal body -->
                  <div class="p-6 space-y-6">
                    <el-tabs tab-position="left" class="demo-tabs">
                      <el-tab-pane v-for="(item, index) in item.regionContent" :key="index" :label="item.building">
                        <div v-show="item.buildingContent[0].info !== undefined" class="mb-4">
                          <p class="text-Primary">项目信息：</p>
                          <p>{{ item.buildingContent[0].info }}</p>
                        </div>
                        <div class="mb-4">
                          <p class="text-Primary">项目优势：</p>
                          <p>{{ item.buildingContent[0].advantage }}</p>
                        </div>
                        <div>
                          <p class="text-Primary">招商经理：</p>
                          <p v-for="(item, key, index) in item.buildingContent[0].manager" :key="index">
                            {{ key }}：{{ item.content }}</p>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="projectIndex === 1" class="mb-8 text-white" v-for="(item, contentIndex) in item.typeContent"
            :key="contentIndex">
            <p class="text-2xl mb-5">{{ item.spaceType }}</p>
            <p>{{ item.spaceContent }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";
import ProjectIndex from "@/pages/project/ProjectIndex.vue";

const { project } = publicData;
export default {
  name: "ProjectAddress",
  components: { ProjectIndex },
  data() {
    return {
      project,
      projectTabNum: 0,
      projectModal: 0,
    };
  },
  methods: {
    getProjectTabNum(val) {
      this.projectTabNum = val;
    },
    openModal(name) {
      this.projectModal = name;
    },
    closeModal() {
      let modal = document.getElementById(this.projectModal + "-modal");
      modal.setAttribute("class", 'hidden');
    }
  }
};
</script>
