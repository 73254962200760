<template>
  <nav>
    <div aria-label="Top" class="navBar" :class="{
      'w-screen': scrollTop > 64, fixedTop: scrollTop > 64, animate__animated: scrollTop > 64, animate__fadeInDown: scrollTop > 64,
    }">
      <!-- animate__animated -->
      <div class="container h-16 flex items-center justify-between">
        <!-- Logo -->
        <div class="logo items-center md:justify-start sm:items-stretch">
          <router-link to="/" class="flex items-center">
            <img class="icon mr-3" src="/img/icon/CHLlogo.png" alt="Cheergo" />
          </router-link>
        </div>

        <!-- navigation -->
        <div class="change-route flex-1 hidden sm:block">
          <el-menu :default-active="activeIndex" mode="horizontal" active-text-color="#3b88c3" text-color="#6b7280">

            <el-menu-item v-show="item.content === undefined" v-for="(item, index) in navigation" :key="item.name"
              :index="index" @click="goRouter(item.toName)">
              {{ item.name }}
            </el-menu-item>
            <!-- <el-sub-menu v-show="item.content !== undefined" v-for="(item, navigationIndex) in navigation"
              :key="item.name" :index="navigationIndex + 1">
              <template #title>{{ item.name }}</template>
              <el-menu-item v-for="(item, index) in item.content" :key="item.name" @click="goRouter(item.toName)"
                :index="`${navigationIndex}-${index + 1}`">
                {{ item.subTitle }}
              </el-menu-item>
            </el-sub-menu> -->
          </el-menu>
        </div>

        <!-- Menu -->
        <button type="button" class="p-2 text-Primary block sm:hidden" @click="open = true">
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>

    <!-- collapse menu -->
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed inset-y-0 right-0 flex z-40" @close="open = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
          enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay class="fixed inset-y-0 right-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
          enter-from="translate-x-full" enter-to="-translate-x-0" leave="transition ease-in-out duration-300 transform"
          leave-from="-translate-x-0" leave-to="translate-x-full">
          <div class="relative w-screen bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
            <div class="h-16 p-4 flex">
              <button type="button" class="-ml-2 px-2 rounded-md inline-flex items-center justify-center text-gray-400"
                @click="open = false">
                <span class="sr-only">Close menu</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div class="border-t border-gray-200 py-6 m-auto my-0 space-y-6 grid text-center">
              <router-link type="button" v-for="item in navigation" :key="item.name" :to="{ name: item.toName }"
                @click="open = false">{{ item.name }}
              </router-link>
            </div>
          </div>
        </TransitionChild>
      </Dialog>
    </TransitionRoot>
  </nav>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import publicData from "@/static/publicData";

const { navigation } = publicData;
export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuIcon,
    XIcon,
  },
  setup() {
    const open = ref(false);

    return {
      open,
    };
  },
  data() {
    return {
      navigation,
      scrollTop: 0,
    };
  },
  methods: {
    // 保存滚动值，这是兼容的写法
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    // 滚动条回到顶部
    backTop() {
      if (this.scrollTop > 10) {
        document.documentElement.scrollTop = 0;
      }
    },
    goRouter(toName) {
      this.$router.push({ name: toName });
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  unmounted() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
