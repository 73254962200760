import { createWebHistory, createRouter } from "vue-router";
import Home from "./pages/home/Home";
import HomeIndex from "./pages/home/HomeIndex";

import About from "./pages/about/About";
import AboutProfolio from "./pages/about/sub/AboutProfolio";
import AboutVision from "./pages/about/sub/AboutVision";
import AboutServe from "./pages/about/sub/AboutServe";
import AboutHistory from "./pages/about/sub/AboutHistory";

import Project from "./pages/project/Project";
import ProjectAddress from "./pages/project/sub/ProjectAddress";

import News from "./pages/news/News";
import NewsEvent from "./pages/news/sub/NewsEvent";
import NewsProject from "./pages/news/sub/NewsProject";

import JoinUs from "./pages/joinUs/JoinUs";
import JoinUsCoWork from "./pages/joinUs/sub/JoinUsCoWork";
import JoinUsWorkPlace from "./pages/joinUs/sub/JoinUsWorkPlace";

const routes = [
  // 找不到網頁
  { path: "/:pathMatch(.*)*", name: "Home", component: Home },
  {
    path: "/",
    component: Home,
    children: [
      {
        name: "Home",
        path: "",
        component: HomeIndex,
      },
    ],
  },
  {
    path: "/about",
    component: About,
    children: [
      {
        name: "AboutProfolio",
        path: "aboutProfolio",
        component: AboutProfolio,
      },
      {
        name: "AboutVision",
        path: "aboutVision",
        component: AboutVision,
      },
      {
        name: "AboutHistory",
        path: "aboutHistory",
        component: AboutHistory,
      },
      {
        name: "AboutServe",
        path: "aboutServe",
        component: AboutServe,
      },
    ],
  },
  {
    path: "/project",
    component: Project,
    children: [
      {
        name: "ProjectAddress",
        path: "projectAddress",
        component: ProjectAddress,
      },
    ],
  },
  {
    path: "/news",
    component: News,
    children: [
      {
        name: "NewsEvent",
        path: "newsEvent",
        component: NewsEvent,
      },
      {
        name: "NewsProject",
        path: "newProject",
        component: NewsProject,
      },
    ],
  },
  {
    path: "/joinUs",
    component: JoinUs,
    children: [
      {
        name: "JoinUsCoWork",
        path: "joinUsCoWork",
        component: JoinUsCoWork,
      },
      {
        name: "JoinUsWorkPlace",
        path: "joinUsWorkPlace",
        component: JoinUsWorkPlace,
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        left: 0,
        top: 0,
      };
    }
  },
});

export default router;
