<template>
  <div class="about">
    <AboutIndex @aboutTabNum="getAboutTabNum" />

    <div class="relative flex flex-col min-w-0 break-words gap-6 profilo">
      <div class="container my-16 flex-auto">
        <div class="max-w-xl float-right p-8 bg-Primary text-white" data-aos="fade-left">
          <p class="text-4xl mb-16">{{ about[aboutTabNum].content.profiloTitle }}</p>
          <p class="leading-8" v-for="(item, index) in about[aboutTabNum].content.profiloContent" :key="index">{{ item
          }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";
import AboutIndex from "@/pages/about/AboutIndex.vue";

const { about } = publicData;
export default {
  name: "AboutProfolio",
  components: { AboutIndex },
  data() {
    return {
      about,
      aboutTabNum: 0,
    };
  },
  methods: {
    getAboutTabNum(val) {
      this.aboutTabNum = val;
    }
  }
};
</script>
