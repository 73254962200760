<template>
  <div class="joinUsTop">
    <div class="banner" />

    <div class="shadow-md h-fit overflow-hidden bg-gray-100">
      <ul class="container flex mb-0 list-none flex-wrap p-4 flex-row">
        <li v-for="(item, index) in joinUs" :key="index" class="-mb-px">
          <a role="button" class="text-sm px-5 m-1 py-3 rounded-md block leading-normal"
            v-on:click="newsToggleTabs(item.index)" v-bind:class="{
              'text-slate-400':
                joinUsTabNum !== item.index,
              'text-white bg-Primary': joinUsTabNum === item.index,
            }">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";

const { joinUs } = publicData;
export default {
  name: "JoinUsIndex",
  data() {
    return {
      joinUs,
      joinUsTabNum: 0,
      nowPage: window.location.href.split('/').pop(),
    };
  },
  methods: {
    newsToggleTabs(tabNumber) {
      this.joinUsTabNum = tabNumber;
      this.$emit("joinUsTabNum", this.joinUsTabNum)

      if (tabNumber === 0) {
        this.$router.push({ name: 'JoinUsCoWork' })
      }
      if (tabNumber === 1) {
        this.$router.push({ name: 'JoinUsWorkPlace' })
      }
    },
  },
  created() {
    setTimeout(() => {
      if (this.nowPage === 'joinUsCoWork') {
        this.joinUsTabNum = 0
      }
      if (this.nowPage === 'joinUsWorkPlace') {
        this.joinUsTabNum = 1
      }
    }, 1)
  }
};
</script>
