<template>
  <div class="about">
    <AboutIndex @aboutTabNum="getAboutTabNum" />

    <div class="relative flex flex-col min-w-0 break-words gap-6 vision">
      <div class="container my-16 flex-auto" data-aos="fade-left">
        <ul class="flex justify-center mb-0 list-none flex-wrap pb-20 flex-row">
          <li v-for="(item, index) in about[aboutTabNum].content" :key="index" class="-mb-px">
            <a role="button" class="px-5 m-1 py-3 rounded-md block leading-normal hover:text-Primary"
              v-on:click="serveToggleTabs(item.index)" v-bind:class="{
                'text-slate-400':
                  serveTabNum !== item.index,
                'text-Primary animate-bounce': serveTabNum === item.index,
              }">
              {{ item.object }}
            </a>
          </li>
        </ul>
        <div v-for="(item, index) in about[aboutTabNum].content" :key="index" v-bind:class="['container',
          {
            hidden: serveTabNum !== item.index,
            block: serveTabNum === item.index,
          },
        ]">
          <div class="card-group gap-x-10">
            <div class="card" v-for="(item, index) in item.detail" :key="index">
              <div class="card-title">{{ item.detailServeTitle }}</div>
              <div class="card-txt">{{ item.detailServeContent }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";
import AboutIndex from "@/pages/about/AboutIndex.vue";

const { about } = publicData;
export default {
  name: "AboutServe",
  components: { AboutIndex },
  data() {
    return {
      about,
      aboutTabNum: 3,
      serveTabNum: 0,
    };
  },
  methods: {
    getAboutTabNum(val) {
      this.aboutTabNum = val;
    },
    serveToggleTabs(tabNumber) {
      this.serveTabNum = tabNumber;
    },
  }
};
</script>
