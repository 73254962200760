import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import "/public/output.css"; // tailwind
import ElementPlus from "element-plus"; // element UI
import "element-plus/dist/index.css";
import AOS from "aos"; // aos
import "aos/dist/aos.css";
import $ from "jquery"; // jquery
import { VueperSlides, VueperSlide } from "vueperslides"; // vueperslides
import "vue-fullpage.js/dist/style.css";
import VueFullPage from "vue-fullpage.js";

const app = createApp(App).use(router);
AOS.init();
window.jquery = window.$ = $;
app.use(ElementPlus);
app.use(AOS);
app.use(VueFullPage);
app.mount("#app");

app.component("vueper-slides", VueperSlides);
app.component("vueper-slide", VueperSlide);
