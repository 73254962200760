<template>
  <div class="home">
    <div class="comingSoon"></div>
    <!-- <full-page :options="options" ref="fullpage">
      <div class="section banner pb-16">
        <div class="container">
          <div class="banner-text" data-aos="fade-left">
            <p class="text-4xl mb-4"> 寰宇上楼CHL </p>
            <p> 将产品和服务打通，衔接商办链，做专业可信赖的商业资产运营商和办公生活服务提供商。 </p>
          </div>
        </div>
      </div>
      <div class="section section-bar-perform pb-16">
        <div class="container">
          <div class="banner-text" data-aos="fade-left">
            <p class="text-4xl mb-16">寰宇上楼CHL management</p>
            <p class="leading-8 text-justify">
              寰宇上楼(武汉)商业运营管理有限公司（以下简称“CHL”）-写字楼轻资产运营服务商，前身为成立于2014年的武汉新时代寰宇置业有限公司。经过8年快速发展，寰宇核心团队探索出一条更符合现今商业资产运营和办公生活服务的全新轻资产运营模式，寰宇上楼应运而生。
            </p>
            <div class="w-full text-right">
              <router-link class="leading-10" type="button" :to="{ name: 'AboutProfolio' }">了解详情</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-bar-serve pb-16">
        <p class="text-center text-4xl my-6 sm:mb-16">企业服务</p>
        <div
          class="mt-6 grid grid-cols-1 gap-y-8 gap-x-8 lg:gap-x-24 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-16 xl:gap-y-16">
          <router-link :to="{ name: 'AboutServe' }" v-for="serve in pmsServe" :key="serve.id"
            class="group relative  hover:text-Primary" role=" button" data-aos="fade-up">
            <div class="text-5xl text-center pb-6 lg:pb-12">
              <i :class="`${serve.iconUrl}`" />
            </div>
            <div class="mt-4">
              <p class="title mb-4 text-center text-xl">
                {{ serve.title }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="section section-bar-project pb-16">
        <div
          class="container my-6 w-3/5 grid justify-items-center grid-cols-1 gap-y-8 xl:gap-y-16 gap-x-12 sm:grid-cols-2 xl:gap-x-16">
          <router-link :to="{ name: 'ProjectAddress' }" v-for="(project, id) in pmsProject" :key="id"
            class="relative rounded-lg overflow-hidden h-32 sm:h-56 w-fit" style="width: -webkit-fill-available;"
            role="button" data-aos="fade-up">
            <img class="w-full h-full hover:scale-125 ease-in duration-300" :src="project.img" :alt="project.region" />
            <div class="two-lines absolute top-8 left-8 pr-8">
              <p class="text-2xl">{{ project.region }}</p>
              <p>{{ project.content }}</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="section section-bar-news pb-16">
        <p class="text-center text-4xl mb-16">企业新闻</p>
        <vueper-slides class="container no-shadow carousel-multi-card" data-aos="zoom-in" :visible-slides="3"
          slide-multiple :gap="1" :slide-ratio="1 / 3" :touchable="false" :bullets="false" :bullets-outside="false"
          :arrows-outside="false" fixed-height="110px" :breakpoints="{
            768: { visibleSlides: 1, slideMultiple: 1 },
          }">
          <vueper-slide :id="`card-${index}`" v-for="(item, index) in pmsNews[0].content" :key="`hisItem${index}`">
            <template #content>
              <router-link :to="{ name: 'NewsEvent' }"
                class="h-full block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
                <h5 class="two-lines text-xl tracking-tight text-gray-900">{{ item }}
                </h5>
              </router-link>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="section fp-auto-height pb-16">
        <div class="fp-tableCell">
          <a-footer />
        </div>
      </div>
    </full-page> -->
  </div>
</template>

<script>
import publicData from "@/static/publicData";
// import AFooter from "@/components/AFooter.vue";

const { pmsServe, pmsProject, pmsNews } = publicData;
export default {
  name: "HomeIndex",
  // components: {
  //   AFooter,
  // },
  data() {
    return {
      pmsServe,
      pmsProject,
      pmsNews,
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        navigation: true,
        anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6'],
        scrollOverflow: true,
        onLeave: function () {
          $('.section [data-aos]').each(function () {
            $(this).removeClass("aos-animate")
          });
        },
        onSlideLeave: function () {
          $('.slide [data-aos]').each(function () {
            $(this).removeClass("aos-animate")
          });
        },
        afterSlideLoad: function () {
          $('.slide.active [data-aos]').each(function () {
            $(this).addClass("aos-animate")
          });
        },
        afterLoad: function () {
          $('.section.active [data-aos]').each(function () {
            $(this).addClass("aos-animate")
          });
        }
      },
    };
  },
};
</script>
