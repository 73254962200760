<template>
  <div class="joinUs">
    <JoinUsIndex @joinUsTabNum="getJoinUsTabNum" />

    <div class="relative flex flex-col min-w-0 break-words gap-6 coWork">
      <div class="container my-16 flex flex-col gap-y-16 text-white">
        <div v-for="(item, index) in joinUs[joinUsTabNum].content" :key="index" data-aos="fade-left">
          <p class="text-4xl mb-11 border-l-4 border-Primary pl-4">{{ item.region }}</p>
          <p class="flex gap-x-10">{{ item.address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";
import JoinUsIndex from "@/pages/joinUs/JoinUsIndex.vue";

const { joinUs } = publicData;
export default {
  name: "JoinUsWorkPlace",
  components: { JoinUsIndex },
  data() {
    return {
      joinUs,
      joinUsTabNum: 1,
    };
  },
  methods: {
    getAboutTabNum(val) {
      this.joinUsTabNum = val;
    }
  }
};
</script>
