<template>
  <div class="about">
    <AboutIndex @aboutTabNum="getAboutTabNum" />

    <div class="relative flex flex-col min-w-0 break-words gap-6 vision">
      <div class="container my-16 flex-auto">
        <div class="h-96 flex">
          <table class="m-auto" data-aos="fade-left">
            <tr class="divide-x-4" v-for="(item, index) in about[aboutTabNum].content" :key="index">
              <td class="text-right w-1/2 pr-11 font-semibold leading-10">
                <p class="text-3xl text-Primary">{{ item[0] }}</p>
                <p class="text-slate-400">COMPANY VISION</p>
              </td>
              <td class="text-left w-1/2 pl-11">{{ item[1] }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";
import AboutIndex from "@/pages/about/AboutIndex.vue";

const { about } = publicData;
export default {
  name: "AboutVision",
  components: { AboutIndex },
  data() {
    return {
      about,
      aboutTabNum: 1,
    };
  },
  methods: {
    getAboutTabNum(val) {
      this.aboutTabNum = val;
    }
  }
};
</script>
