<template>
  <div class="project">
    <div class="banner" />

    <div class="shadow-md h-fit overflow-hidden bg-gray-100">
      <ul class="container flex mb-0 list-none flex-wrap p-4 flex-row">
        <li v-for="(item, index) in project" :key="index" class="-mb-px">
          <a role="button" class="text-sm px-5 m-1 py-3 rounded-md block leading-normal"
            v-on:click="projectToggleTabs(item.index)" v-bind:class="{
              'text-slate-400':
                projectTabNum !== item.index,
              'text-white bg-Primary': projectTabNum === item.index,
            }">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";

const { project } = publicData;
export default {
  name: "ProjectIndex",
  data() {
    return {
      project,
      projectTabNum: 0,
      nowPage: window.location.href.split('/').pop(),
    };
  },
  methods: {
    projectToggleTabs(tabNumber) {
      this.projectTabNum = tabNumber;
      this.$emit("projectTabNum", this.projectTabNum)

      if (tabNumber === 0) {
        this.$router.push({ name: 'ProjectAddress' })
      }
    },
  },
  created() {
    setTimeout(() => {
      if (this.nowPage === 'projectAddress') {
        this.aboutTabNum = 0
      }
    }, 1)
  }
};
</script>
