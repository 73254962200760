export default {
  //nav
  navigation: [
    {
      name: "首页",
      toName: "Home",
    },
    {
      name: "走进CHL",
      toName: "AboutProfolio",
      content: [
        { subTitle: "企业简介", toName: "AboutProfolio" },
        { subTitle: "企业愿景", toName: "AboutVision" },
        { subTitle: "寰宇上楼发展历程", toName: "AboutHistory" },
        { subTitle: "企业服务", toName: "AboutServe" },
      ],
    },
    {
      name: "CHL项目",
      toName: "ProjectAddress",
      content: [{ subTitle: "企业选址", toName: "ProjectAddress" }],
    },
    {
      name: "企业新闻",
      toName: "NewsEvent",
      content: [
        { subTitle: "寰宇大事件", toName: "NewsEvent" },
        { subTitle: "寰宇新项目", toName: "NewsProject" },
      ],
    },
    {
      name: "加入我们",
      toName: "JoinUsCoWork",
      content: [
        { subTitle: "期待共事", toName: "JoinUsCoWork" },
        { subTitle: "工作地点", toName: "JoinUsWorkPlace" },
      ],
    },
  ],

  //企業服務
  pmsServe: [
    {
      id: 1,
      title: "商业运营",
      iconUrl: "fas fa-browser",
    },
    {
      id: 2,
      title: "物业服务",
      iconUrl: "fas fa-file-chart-line",
    },
    {
      id: 3,
      title: "企业服务",
      iconUrl: "fas fa-globe-americas",
    },
    {
      id: 4,
      title: "孵化器运营",
      iconUrl: "fas fa-bookmark",
    },
  ],

  //企業項目
  pmsProject: [
    {
      region: "武汉",
      content: "icc、泷悦国际、汉街总部国际、喜年中心",
      img: "/img/home/area/Wuhan.jpg",
    },
    {
      region: "苏州",
      content: "苏州中心、苏悦广场、凤凰国际、丰隆国际",
      img: "/img/home/area/Suzhou.jpg",
    },
    {
      region: "重庆",
      img: "/img/home/area/Chongqing.jpg",
    },
    {
      region: "西安",
      img: "/img/home/area/Xian.jpg",
    },
  ],

  //走進寰宇
  about: [
    {
      index: 0,
      title: "企业简介",
      engTitle: "profolio",
      content: {
        profiloTitle: "寰宇上楼CHL management",
        profiloContent: [
          "寰宇上楼(武汉)商业运营管理有限公司（以下简称“CHL”）-写字楼轻资产运营服务商，前身为武汉新时代寰宇置业有限公司，成立于2014年。为顺应时代发展趋势，于2022年正式开启写字楼轻资产托管模式，战略进军全国新一线城市，总部设立于武汉·汉街，注册资金3000万元，主要服务于高端商业地产，目前项目遍布武汉、苏州、西安和重庆核心商业地段。",
          "围绕孵化器、物业服务、企业服务、商业运营四大板块，通过CHL management独有的模块化产品服务和科技化中台管理，为客户提供全链路商业运营服务。可提供投前咨询服务、项目定位与规划、设计与施工、招商去化、室内定制化装修、智能化物联网搭建、企业运营、日常维保等常态化全流程运营服务。",
        ],
      },
    },
    {
      index: 1,
      title: "企业愿景",
      engTitle: "vision",
      content: [
        ["公司願景", "打造楼宇轻资产运营核心能力，推动新一线城市创新发展。"],
      ],
    },
    {
      index: 2,
      title: "寰宇上楼发展历程",
      engTitle: "history",
      content: [
        {
          time: "未來",
          event:
            "寰宇上楼将成为新一线城市首选的，商业资产运营和办公生活服务专家供应商",
        },
        {
          time: "2022年",
          event:
            "成立寰宇上楼（武汉）商业运营管理有限公司，开启全新轻资产运营模式",
        },
        {
          time: "2021年",
          event: "旗下东厦商业管理公司成立，收购川岚商业管理（苏州）有限公司",
        },
        {
          time: "2020年",
          event: "积极应对疫情，逆流而上，入驻苏州地标项目苏州中心",
        },
        {
          time: "2019年",
          event: "客户总数突破500家，管理项目整体入住率达到97%",
        },
        {
          time: "2018年",
          event:
            "武汉项目全面铺开，业务拓展至苏州，管理面积10万㎡，团队规模50人",
        },
        {
          time: "2017年",
          event: "团队规模30人，管理面积超过5万㎡",
        },
        {
          time: "2016年",
          event: "入驻徐东金禾中心，第一个开发商自持项目诞生",
        },
        {
          time: "2015年",
          event: "管理面积超1.5万m²，团队人数16人",
        },
        {
          time: "2014年4月18日",
          event: "新时代寰宇公司成立，正式进驻光谷",
        },
      ],
    },
    {
      index: 3,
      title: "企业服务",
      engTitle: "serve",
      content: [
        {
          index: 0,
          object: "商业运营",
          detail: [
            {
              detailServeTitle: "投前咨询服务",
              detailServeContent:
                "利用商业大数据进行专业的行业研究，分析项目区域发展趋势、商业环境、行业环境和企业类型等因素，为企业提供专业投资前咨询服务，让寰宇客户的每一笔投资获得更好的商业回报。",
            },
            {
              detailServeTitle: "项目定位与规划",
              detailServeContent:
                "针对商业资产持有者，研究分析商业资产项目区域发展趋势、项目地段、商业环境、行业环境和目标企业类型等因素，为项目制定具有差异化的项目定位，准确切入目标企业和目标行业，并为项目快速去化执行详尽执行路径。",
            },
            {
              detailServeTitle: "项目设计与施工",
              detailServeContent:
                "专业产品团队，汇聚全国设计精英，为项目量身定制高端设计规划；专业施工团队，10年以上装修施工经验，结合全国顶尖装修建材供应渠道，让项目装修既升值。",
            },
            {
              detailServeTitle: "项目招商去化",
              detailServeContent:
                "招商团队精选行业优秀专业人才，专项专案，针对大客户提供1对1定制化服务，导入寰宇优质客户私域流量池，确保高效招商去化。",
            },
          ],
        },
        {
          index: 1,
          object: "物业服务",
          detail: [
            {
              detailServeTitle: "写字楼房屋维修服务",
              detailServeContent:
                "寰宇上楼对管理项目房屋主体结构、外立面、内部设施等提供2小时内相应服务，确保98%维修及时率和100%质量合格率。",
            },
            {
              detailServeTitle: "公共设备管理服务",
              detailServeContent:
                "对写字楼供电系统、供水，系统、供暖系统（如有）以及写字楼电梯等公共设备进行定期养护和维修服务。",
            },
            {
              detailServeTitle: "公共绿化区域管理及服务",
              detailServeContent:
                "对写字楼绿化提供专人养护和管理，打造绿色办公环境配套。",
            },
            {
              detailServeTitle: "公共设施的管理及服务",
              detailServeContent:
                "规范管理和维护写字楼标识、公共道路、污水、照明等公共设施，确保写字楼高品质运转。",
            },
          ],
        },
        {
          index: 2,
          object: "企业服务",
          detail: [
            {
              detailServeTitle: "企业选址",
              detailServeContent:
                "寰宇上楼在武汉、苏州、西安和重庆核心商业区域拥有大量优质项目面积，为新一线城市大中小型企业客户提供称心办公地址。",
            },
            {
              detailServeTitle: "室内定制化装修",
              detailServeContent:
                "寰宇上楼提供入驻定制化装修服务，根据企业办公职能、办公规模和行业属性等因素，制定符合企业发展的高效办公室装修方案和施工。",
            },
            {
              detailServeTitle: "智能化物联网搭建",
              detailServeContent:
                "寰宇上楼提供现代办公室智能化物联网解决方案，在企业办公流程和关键环节中植入物联网软硬件设施，升级入驻企业办公模式。",
            },
            {
              detailServeTitle: "企业运营",
              detailServeContent:
                "寰宇上楼为入驻企业提供系列企划服务，包括企业工商代办、财税代办、企业投融资服务、办公设备维护与租赁、行业资源共享和人才招聘等服务。",
            },
            {
              detailServeTitle: "日常维保",
              detailServeContent:
                "入驻企业可获得寰宇提供的专业日常维保，包含办公设施、办公设备的保养与维修服务，让办公室常驻常新。",
            },
          ],
        },
        {
          index: 3,
          object: "孵化器运营",
          detail: [
            {
              detailServeTitle: "空间共享",
              detailServeContent:
                "面向初创型企业提供核心地段共享空间及共享设施",
            },
            {
              detailServeTitle: "入驻扶持",
              detailServeContent:
                "提供专业企划及公司运营服务，为创业团队快速发展保驾护航",
            },
            {
              detailServeTitle: "行业资源",
              detailServeContent:
                "整合行业伙伴信息，为创业公司提供上下游合作资源支撑，帮助创业团队快速成长",
            },
          ],
        },
      ],
    },
  ],

  //寰宇項目
  project: [
    {
      index: 0,
      title: "企业选址",
      content: [
        {
          type: "所在城市",
          engType: "city",
          typeContent: [
            {
              area: "武汉",
              areaEng: "Wuhan",
              areaContent: [
                {
                  region: "汉口",
                  regionEng: "Hankou",
                  regionContent: [
                    {
                      building: "ICC武汉环贸中心",
                      buildingContent: [
                        {
                          info: "17000㎡/甲级写字楼",
                          advantage:
                            "项目由武汉怡景地产开发，位于建设大道与长江大道交汇口，汉口CBD，包含商务、生活、商业、文化、生态、休闲于一体，临近地铁2号线和7号线及大量公交站，交通极其便利。建筑体属于绿色环保建筑，获得美国LEED金级认证。户型面积94-1809㎡自由选择。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "花样年·喜年中心",
                      buildingContent: [
                        {
                          info: "14700㎡/甲级写字楼",
                          advantage:
                            "项目是汉正街重点改造项目甲级商务办公产品，由华通置业发展有限公司开发，位于硚口区长江、汉江交汇处，拥有月湖、龟山等优质景观资源。临近6号线地铁，交通便利。项目配套共享会议室、妈咪屋、咖啡书吧、自助KTV、健身会所等诸多设施，拥有一体化智能停车系统，户型面积117-1697㎡自由搭配。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "广发银行大厦",
                      buildingContent: [
                        {
                          info: "4100㎡/5A甲级写字楼",
                          advantage:
                            "项目由湖北新海盛顿置业有限公司开发，位于建设大道，临近新华路和台北路，区位属于汉口最早的金融CBD，汇集金融总部、证券总部、甲级写字楼和五星级酒店等高端成熟产业集群。毗邻地铁2号线，3号线，40多条公交线路在此汇聚，通达武汉三镇。单层1300平面积，90-386㎡精装办公室，可进行深度定制。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "江尚·世纪中心",
                      buildingContent: [
                        {
                          info: "16套房源/甲级写字楼",
                          advantage:
                            "项目由和记黄埔地产开发，著名设计事务所Aedas以国际大都会生活概念设计。项目包含豪华住宅，大型商业街区和滨江写字楼。连接江汉路步行街， 临近地铁2号线、6号线，50多个公交线路，交通便利。70-362㎡房型随心选择，小投入，大产出。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  region: "武昌",
                  regionEng: "Wuchang",
                  regionContent: [
                    {
                      building: "泷悦国际",
                      buildingContent: [
                        {
                          info: "67500㎡/5A甲级写字楼",
                          advantage:
                            "项目由南国置业和电建地产联合开发建设， 位于关山大道与高新大道交汇处，雄楚大道高架和光谷大道高架在此贯穿，地铁2号线与T1有轨电车无缝衔接，集5A甲级写字楼、低密度住宅、英伦风商业街和公寓为一体，标准层1904㎡大型空间供深度定制。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "汉街总部国际",
                      buildingContent: [
                        {
                          info: "15300㎡/5A甲级写字楼",
                          advantage:
                            "项目由武汉万达开发，坐落在楚河汉街两岸。项目汇集6栋5A甲级写字楼，拥有汉街商业步行街，两座豪华酒店，汉秀剧场和医院、学校等豪华配套设施。地铁4号线和8号线横贯左右，40多条公交线路通达三镇。单层超1750平，户型100-1000㎡，满足各种办公需求。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "慧谷大厦",
                      buildingContent: [
                        {
                          info: "6750㎡/甲级写字楼",
                          advantage:
                            "项目由铁四院开发设计，周边大型企业林立，项目本身集办公空间、众创空间、商业、SOHO办公等功能为一体，商业配套齐全。地铁11号线途经项目楼盘，与数条公交线路一同连接市区与光谷东。125-306㎡办公空间，是中小型企业办公地址不二之选。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "宜科中心",
                      buildingContent: [
                        {
                          info: "12900㎡/甲级写字楼",
                          advantage:
                            "项目位于高新大道近光谷一路，周边大学、办公、商业配套一应俱全，自带底商，便利店、餐饮齐全。地铁11号线途经项目，高新技术企业汇聚于此，形成了强有力的人才及行业环境集群优势。办公区域125-1560平自由组合，满足大中小型企业全方位需求。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "光谷融众国际",
                      buildingContent: [
                        {
                          info: "3300㎡/5A甲级写字楼",
                          advantage:
                            "项目由武汉融城置业有限公司开发， 紧邻光谷广场，与光谷国际广场并肩而立，拥有豪华大堂、PORIS智能车位指引、多联机分层式中央空调等人性化豪华配置。毗邻地铁2、11号线，数十条公交线路连接三镇。空间户型104-358㎡自由选择，轻松占领光谷中心。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "新时代商务中心（中建三局写字楼）",
                      buildingContent: [
                        {
                          info: "3000㎡/5A甲级写字楼",
                          advantage:
                            "项目由中建三局开发，地处武昌金融CBD中南路路口，总高度达到208米。项目配置3000平方大型绿化广场、600+车位智慧车库和直饮水服务系统。周边餐饮、医疗、金融、商务配套齐全。地铁2号线、4号线在此交汇。办公空间159-453㎡米自由选择。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "绿地楚峰大厦",
                      buildingContent: [
                        {
                          info: "2000m²/5A甲级写字楼",
                          advantage:
                            "项目由武汉绿地滨江置业开发，紧邻武汉地标绿地中心大厦，项目配备中央新风系统，按照5A标准配备豪华洗手间和公共茶水间，并搭载直饮水系统。	项目拥有180°江景景观，毗邻地铁5号线，40余条公交线。单层1700平，4.5米空高,197-400㎡豪华办公室满足企业所需。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              area: "苏州",
              areaEng: "Suzhou",
              areaContent: [
                {
                  region: "苏州工业园",
                  regionEng: "SuzhouIndustrialPark",
                  regionContent: [
                    {
                      building: "苏州中心",
                      buildingContent: [
                        {
                          info: "7600m²/5A超甲级写字楼",
                          advantage:
                            "项目由苏州工业园区金鸡湖城市发展有限公司整体开发建设，是苏州的地标建筑，坐落于苏州工业园湖西CBD核心，集商业、现代化办公楼、服务型CBD公寓、酒店等多业态于一身。地铁1号线与3号线交叉穿越，配合公交循环线、环线高架及高速路网，通达长三角。户型110-1000m²，企业规模全覆盖。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "凤凰国际大厦",
                      buildingContent: [
                        {
                          info: "47800㎡/5A甲级写字楼",
                          advantage:
                            "项目由江苏凤凰置业开发，位于苏州大道西与星桂街交汇处，地铁1号线星海广场直达。总层数42层，标准层高4.2米，净高2.8米，16部锋速电梯，VRV中央空调独立空调。133-204㎡精装办公室，中小企业成长首选。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "苏悦广场",
                      buildingContent: [
                        {
                          info: "22200㎡/5A甲级写字楼",
                          advantage:
                            "项目地处苏州工业园区，由美国开发商铁狮门倾力打造，是涵盖甲级写字楼、购物中心、精装公寓的地标性城市综合体。获得LEED金级认证，地铁1号线星海广场上盖，在建3号线步行5分钟。高铁25分钟通往上海，1-2小时畅达长三角。户型99-1500㎡，企业可灵活选择。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "丰隆城市中心",
                      buildingContent: [
                        {
                          info: "17600㎡/5A甲级写字楼",
                          advantage:
                            "项目是由新加坡丰隆集团旗下城市发展（中国）有限公司CDL与建屋集团合资开发的城市综合体。坐落于苏州工业园区主干道钟园路以北，思安街以西，包含一座 4 层商业零售裙楼及 4 栋塔楼，容纳办公、SOHO公寓、住宅及酒店设施。户型覆盖150-1000㎡，符合各种办公规模需求。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                    {
                      building: "中新大厦",
                      buildingContent: [
                        {
                          advantage:
                            "坐落于独墅湖月亮湾核心商务区，位于月亮湾路11号地铁二号线无缝对接，超甲级写字楼，独墅湖区域222米地标建筑，湖景房，18米挑奢装大堂，18部进口电梯直达云端，户型方正，得房率高，标准层2200㎡，层高2.8米6，总面积10万方，总层数49层，车位726个。",
                          manager: {
                            照片: { content: "picture" },
                            姓名: { content: "name" },
                            電話: { content: "phone" },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            { area: "西安", areaEng: "Xian" },
            { area: "重庆", areaEng: "Chongqing" },
          ],
        },
        {
          type: "空间类型",
          engType: "spaceType",
          typeContent: [
            {
              spaceType: "精装办公室",
              spaceContent:
                "寰宇上楼根据办公空间规模和特性，对空间进行科学规划和设计，采用高端环保材料施工装修，为入驻企业打造居住式办公体验。",
            },
            {
              spaceType: "联合办公工位",
              spaceContent:
                "寰宇上楼在城市中心CBD打造云端商务中心，提供开放共享和办公空间和办公氛围，为创业企业提供创业乐土。",
            },
          ],
        },
      ],
    },
  ],

  //企業新聞
  pmsNews: [
    {
      index: 0,
      title: "寰宇大事件",
      content: [
        "寰宇上楼（武汉）商业运营管理有限公司正式成立！",
        "5A甲级写字楼泷悦国际与小红书达成24000m²签约",
        "寰宇上楼正式进驻重庆！",
        "寰宇上楼正式进驻西安！",
        "寰宇上楼重庆12万m²商办场地开始定制装修",
      ],
    },
    {
      index: 1,
      title: "寰宇新项目",
      content: [
        {
          object: "泷悦国际",
          objectContent: "光谷（关山口）新地标，科技企业。",
        },
      ],
    },
  ],

  //加入我們
  joinUs: [
    {
      index: 0,
      title: "期待共事",
      content: [
        { dept: "招商部", deptContent: ["招商经理", "招商专员"] },
        {
          dept: "运营部",
          deptContent: ["客服专员", "楼管主管", "巡楼员", "维修员"],
        },
      ],
    },
    {
      index: 1,
      title: "工作地点",
      content: [
        { region: "武汉", address: "武汉市武昌区汉街总部国际F座1902" },
        { region: "苏州", address: "苏州市工业园区凤凰国际大厦2102室" },
      ],
    },
  ],

  footerInfo: [
    { title: "总部地址：", content: "XXX", icon: "fas fa-map-marker-alt" },
    { title: "服务热线：", content: "XXX", icon: "fas fa-phone" },
    { title: "招商热线：", content: "XXX", icon: "fas fa-phone" },
    { title: "电子邮箱：", content: "XXX", icon: "fas fa-envelope" },
  ],
};
