<template>
  <div class="about">
    <AboutIndex @aboutTabNum="getAboutTabNum" />

    <div class="relative flex flex-col min-w-0 break-words gap-6 profilo">
      <div class="container my-16 flex-auto">
        <div class="flex flex-col justify-center py-6 lg:py-12">
          <div class="w-full mx-auto lg:max-w-4xl">
            <div class="relative">
              <div class="absolute hidden w-px h-full transform -translate-x-1/2 bg-Primary lg:block left-1/2">
              </div>
              <div class="space-y-12 lg:space-y-8">
                <div v-for="(item, index) in about[aboutTabNum].content" :key="index" data-aos="zoom-in">
                  <div class="flex flex-col items-center">
                    <div class="flex items-center w-full mx-auto" :class="{ 'justify-end': index % 2 !== 0 }">
                      <div class="w-full lg:w-1/2" :class="{ 'lg:pl-8': index % 2 !== 0 }">
                        <div class="px-16">
                          <p class="text-2xl font-semibold mb-6">{{ item.time }}</p>
                          <p>{{ item.event }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="absolute flex items-center justify-center w-5 h-5 transform -translate-x-1/2 -translate-y-4 bg-Primary border-2 border-Primary rounded-full left-1/2 sm:translate-y-0">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";
import AboutIndex from "@/pages/about/AboutIndex.vue";

const { about } = publicData;
export default {
  name: "AboutHistory",
  components: { AboutIndex },
  data() {
    return {
      about,
      aboutTabNum: 2
    };
  },
  methods: {
    getAboutTabNum(val) {
      this.aboutTabNum = val;
    }
  }
};
</script>
