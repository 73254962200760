<template>
  <a-navbar />

  <router-view />

  <a-footer />
</template>

<script>
import ANavbar from "@/components/ANavbar.vue";
import AFooter from "@/components/AFooter.vue";
import "./styles/style.scss";

export default {
  name: "App",
  components: {
    ANavbar,
    AFooter,
  },
};
</script>
