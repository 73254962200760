<template>
  <router-view />
</template>

<script>

export default {
  name: "JoinUs",
};
</script>
