<template>
  <div class="newsTop">
    <div class="banner" />

    <div class="shadow-md h-fit overflow-hidden bg-gray-100">
      <ul class="container flex mb-0 list-none flex-wrap p-4 flex-row">
        <li v-for="(item, index) in pmsNews" :key="index" class="-mb-px">
          <a role="button" class="text-sm px-5 m-1 py-3 rounded-md block leading-normal"
            v-on:click="newsToggleTabs(item.index)" v-bind:class="{
              'text-slate-400':
                newsTabNum !== item.index,
              'text-white bg-Primary': newsTabNum === item.index,
            }">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";

const { pmsNews } = publicData;
export default {
  name: "NewsIndex",
  data() {
    return {
      pmsNews,
      newsTabNum: 0,
      nowPage: window.location.href.split('/').pop(),
    };
  },
  methods: {
    newsToggleTabs(tabNumber) {
      this.newsTabNum = tabNumber;
      this.$emit("newsTabNum", this.newsTabNum)

      if (tabNumber === 0) {
        this.$router.push({ name: 'NewsEvent' })
      }
      if (tabNumber === 1) {
        this.$router.push({ name: 'NewsProject' })
      }
    },
  },
  created() {
    setTimeout(() => {
      if (this.nowPage === 'newsEvent') {
        this.newsTabNum = 0
      }
      if (this.nowPage === 'newProject') {
        this.newsTabNum = 1
      }
    }, 1)
  }
};
</script>
