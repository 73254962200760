<template>
  <div class="aboutTop">
    <div class="banner" />

    <div class="shadow-md h-fit overflow-hidden bg-gray-100">
      <ul class="container flex mb-0 list-none flex-wrap p-4 flex-row">
        <li v-for="(item, index) in about" :key="index" class="-mb-px">
          <a role="button" class="text-sm px-5 m-1 py-3 rounded-md block leading-normal"
            v-on:click="aboutToggleTabs(item.index)" v-bind:class="{
              'text-slate-400':
                aboutTabNum !== item.index,
              'text-white bg-Primary': aboutTabNum === item.index,
            }">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";

const { about } = publicData;
export default {
  name: "AboutIndex",
  data() {
    return {
      about,
      aboutTabNum: 0,
      nowPage: window.location.href.split('/').pop(),
    };
  },
  methods: {
    aboutToggleTabs(tabNumber) {
      this.aboutTabNum = tabNumber;
      this.$emit("aboutTabNum", this.aboutTabNum)

      if (tabNumber === 0) {
        this.$router.push({ name: 'AboutProfolio' })
      }
      if (tabNumber === 1) {
        this.$router.push({ name: 'AboutVision' })
      }
      if (tabNumber === 2) {
        this.$router.push({ name: 'AboutHistory' })
      }
      if (tabNumber === 3) {
        this.$router.push({ name: 'AboutServe' })
      }
    },
  },
  created() {
    setTimeout(() => {
      if (this.nowPage === 'aboutProfolio') {
        this.aboutTabNum = 0
      }
      if (this.nowPage === 'aboutVision') {
        this.aboutTabNum = 1
      }
      if (this.nowPage === 'aboutHistory') {
        this.aboutTabNum = 2
      }
      if (this.nowPage === 'aboutServe') {
        this.aboutTabNum = 3
      }
    }, 1)
  }
};
</script>
