<template>
  <div class="news">
    <NewsIndex @newsTabNum="getAboutTabNum" />

    <div class="relative flex flex-col min-w-0 break-words gap-6 event" data-aos="fade-left">
      <div class="container my-16 flex flex-col gap-y-4">
        <div v-for="(item, index) in pmsNews[newsTabNum].content" :key="index">
          <p class="text-4xl mb-8 border-l-4 border-Primary pl-4">{{ item.object }}</p>
          <div class="p-6 flex justify-between bg-white rounded-lg border border-gray-200 shadow-md">
            <a href="#">
              <h5 class="text-md font-bold">{{ item.objectContent }}</h5>
            </a>
            <!-- readMore按鈕 -->
            <!-- <a href="#" class="text-sm inline-flex items-center py-2 px-3 fill-btn">
              Read more
              <svg aria-hidden="true" class="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
              </svg>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicData from "@/static/publicData";
import NewsIndex from "@/pages/news/NewsIndex.vue";

const { pmsNews } = publicData;
export default {
  name: "NewsProject",
  components: { NewsIndex },
  data() {
    return {
      pmsNews,
      newsTabNum: 1,
    };
  },
  methods: {
    getAboutTabNum(val) {
      this.newsTabNum = val;
    },
  },
};
</script>
