<template>
  <footer>
    <div class="innerFooter container">
      <div class="md:flex md:justify-around">
        <div class="mb-6 md:mb-0 flex flex-col items-center justify-evenly">
          <div class="logo text-2xl items-center md:justify-start sm:items-stretch">
            <router-link to="/" class="flex items-center">
              <img class="icon mr-3" src="/img/icon/CHLlogo.png" alt="Cheergo" />
            </router-link>
          </div>
        </div>
        <div class="px-8">
          <div class="grid grid-cols-2 sm:grid-cols-4 gap-8 sm:gap-20">
            <div v-for="(item, index) in navigation.slice(1)" :key="`pc_map${index}`">
              <div class="leading-10 text-sm font-semibold text-gray-900">
                {{ item.name }}
              </div>
              <ul class="text-gray-600">
                <li class="mb-4 hover:text-Primary" v-for="(item, index) in item.content" :key="`item${index}`">
                  <!-- <router-link :to="{ name: item.toName }">
                    {{ item.subTitle }}
                  </router-link> -->
                  {{ item.subTitle }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
      <div class="flex flex-col items-center sm:flex-row sm:justify-between">
        <p class="text-sm text-gray-500 sm:text-center">备案号 ： 鄂ICP备2022009389号</p>
        <p class="text-sm text-gray-500 sm:text-center">
          {{
              `© ${new Date().getFullYear()} CHL寰宇上楼`
          }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import publicData from "@/static/publicData";

const { navigation, footerInfo } = publicData;
export default {
  data() {
    return {
      navigation,
      footerInfo,
      footerItems: {
        icons: [
          {
            name: "fb",
            iconUrl: "fab fa-facebook-f",
            value: "fb",
            link: "",
          },
          {
            name: "google",
            iconUrl: "fab fa-google",
            value: "google",
            link: "",
          },
          {
            name: "mail",
            iconUrl: "far fa-envelope",
            value: "E-mail",
            link: "",
          },
        ],
      },
    };
  },
  created() {
    console.log(window.location.href);
  }
};
</script>
